import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from "pinia";
import { createGtm } from '@gtm-support/vue-gtm';
import Hotjar from 'vue-hotjar';
import './assets/scss/main.scss'; // Custom styles

const pinia = createPinia();

localStorage.theme = 'light'; // Set default theme

async function initApp() {
  const i18n = await (await import('./i18n')).default; // Import and await i18n instance

  createApp(App)
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(createGtm({
      id: 'GTM-NJ767RWJ',
      loadScript: process.env.NODE_ENV === 'production',
      vueRouter: router,
    }))
    .use(Hotjar, {
      id: '5312937',
      isProduction: process.env.NODE_ENV === 'production'
    })
    .mount('#app');

}

// Initialize and mount the app
initApp();
