import { defineStore } from 'pinia';
import { axiosRequest } from '@core/composables/helpers/axios-requests';
import { nrLogs } from '@core/composables/useNewRelic.js'
import { useStorage } from "vue3-storage";
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import router from '@/router';
import { useUserStore, useMembershipStore } from '@core/stores';
import { useI18n } from "vue-i18n";
import track from '@core/composables/useGTM'
import { ClientJS } from 'clientjs';

const storage = useStorage();
const route = useRoute();
const qryParams = ref('');


export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        token: storage.getStorageSync("customerJWT"),
        err: null,
        i18: useI18n(),
    }),
    actions: {
        async signup(payLoad){
            // Create a new ClientJS object
            const client = new ClientJS();

            // Get the client's fingerprint id
            const fingerprint = client.getFingerprint();

            // Add the 32bit hash id to the payload
            payLoad.browserFingerprintId = String(fingerprint)

            const token = await axiosRequest.post('/customers', payLoad);
            const {t} = this.i18;
            
            if(!token){
                this.err = t("auth.error");
            }
            else {

                let nrPayload = {
                    email: payLoad.userName.split('|')[1],
                    firstName: payLoad.firstName,
                    lastName: payLoad.lastName,
                };
                nrLogs('SIGN_IN_ATTEMPT', nrPayload);

                if(![409, 400, 403].includes(token.status)) {
                    storage.setStorageSync("customerJWT", token.data.token);
                    this.token = token.data.token;
                    storage.setStorageSync("select-readers", 'select-readers');

                    // Redirects from Readers to ChatBox
                    const rtClickId = storage.getStorageSync("rtClickId");
                    await this.useLogin(this.token && rtClickId ? 'Readers' : 'SelectReaders');
                    if(router.currentRoute.value.path === '/select-readers'){
                        // GTM sign_up event
                        track.signUp();

                        /**
                         * Klaviyo Metrics
                         */
                        const customerStorage = await storage.getStorageSync('customerInfo');
                        if (klaviyo && customerStorage) {
                            
                            let profileId = customerStorage.profile._id.split('|')[1];
                            const identifyProperties = {
                            'email' : profileId,
                            'first_name' : customerStorage.profile.firstName,
                            'last_name' : customerStorage.profile.lastName,
                            'birth_date' : customerStorage.profile.birthDate,
                            }

                            // Callback function
                            const onIdentifyCompleteCallback = () =>  {
                            klaviyo.push(['track', 'New Accounts', router.currentRoute.value.path]);
                            }
                            
                            // Identify user then send custom event for the identified user
                            klaviyo.identify(identifyProperties, onIdentifyCompleteCallback);

                        } else {
                            console.error('Klaviyo script not loaded');
                        }
                    }
                }
                else {
                    if([409].includes(token.status)){
                        this.err = t("auth.errorUser");
                        nrLogs('SIGN_IN_FAILED', { error: error, ...nrPayload });
                    }
                    else {
                        this.err = t("auth.errorInvalid");
                    }
                }
            }
        },
        async loginByToken(token){
            storage.setStorageSync("customerJWT", token);
            this.token = token;
            const info = JSON.parse(atob(token.split('.')[1]));
            // Extract the email from the token
            const nrPayload = { email: info.sub.split('|')[1] };
            // Log the sign in attempt for campaign users
            nrLogs('SIGN_IN_CAMPAIGN_USER', nrPayload);
            // Use the login function to navigate to the Readers page
            this.useLogin('Readers');
        },
        async login(payLoad){
            const token = await axiosRequest.post('/customers/login', payLoad);
            const {t} = this.i18;
            
            let nrPayload = {
                email: payLoad.userName.split('|')[1],
            };
            nrLogs('SIGN_IN_ATTEMPT', nrPayload);

            if(!token){
                this.err = t("auth.error");
                nrLogs('SIGN_IN_FAILED', { error: this.err, ...nrPayload });
            }
            else {
                if(![409, 400, 403].includes(token.status)) {
                    storage.setStorageSync("customerJWT", token.data.token);
                    this.token = token.data.token;
                    this.useLogin('Readers');
                }
                else {
                    this.err = t("auth.incorrect");
                    nrLogs('SIGN_IN_FAILED', { error: this.err, ...nrPayload });
                }
            }
        },
        async useLogin(path){
            if(sessionStorage.getItem('fbclid') && !sessionStorage.getItem('lead')) {
                qryParams.value = { 'fbclid': sessionStorage.getItem('fbclid') };
            }
            if(this.token){
                router.push({ name: path, query: qryParams.value });
                const userStore = useUserStore();
                await userStore.getUser(this.token);

                let nrPayload = {
                    email: userStore.user.profile._id.split('|')[1],
                    firstName: userStore.user.profile.firstName,
                    lastName: userStore.user.profile.lastName,
                };

                nrLogs('SIGN_IN_SUCCESS', nrPayload);

            }
        },
        setToken(token){
            storage.setStorageSync("customerJWT", token);
            this.token = token;
        },
        logout(){
            this.token = null;
            storage.clearStorageSync();
            sessionStorage.clear();
            router.push({name: 'Login'});
        },
        clearErrMsg(){
            this.err = null;
        }
    },
    getters: {
        errMsg: state => state.err,
        isAuthenticated: state => !!state.token
    },
});
