<template>
  <navbar v-if="displaySection">
    <template #logo>
      <!-- logo  -->
      <img src="@/assets/img/logo.svg">
      <h3>{{company.brand}}</h3>
    </template>
  </navbar>
  <main>
    <router-view/>
  </main>
  <Footer v-if="displaySection"/>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@core/stores';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import company from '@/data/company.json';
import { createAppendScript, getQryParams } from '@core/composables/utilities';

const route = useRoute();
const authStore = useAuthStore();
const urlParams = getQryParams();
const routeToHide = ['Payment'];

// Display the section if the route is not in the routeToHide array
const displaySection = computed(() => !routeToHide.includes(route.name));

/**
 * Add custom script in the APP
*/
const envMod = process.env.NODE_ENV;
switch (envMod) {
  case 'production':
    createAppendScript('../assets/js/new_relic.js');
    createAppendScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TcrYnT');
    break;
  case 'development':
    createAppendScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=Sfvy9P');
  break;
  default:
    break;
}

/**
 * Initialize the campaign user
*/
const initCampaignUser = () => {
  // Check if the campaign token is present
  const campaignToken = urlParams.get('ct');
  if(!campaignToken) return;
  // Log the sign in attempt for campaign users
  authStore.loginByToken(campaignToken);
}

/**
 * Initialize the campaign user
*/
onMounted(() => {
  initCampaignUser();
});

</script>
